import { Section } from '@app/core/models/sidenav.model';
import { URL, SECTIONS } from './url';

export const navList: Section[] = [
  // {
  //   name: 'Dashboard',
  //   icon: 'home',
  //   path: URL.HOME,
  //   isExpanded: false,
  //   subFolders: [],
  // },
  {
    name: 'OPS User',
    icon: 'face',
    path: SECTIONS.ADMINS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Internal Users',
        icon: 'people',
        path: URL.ADMIN,
      },
      {
        name: 'Roles',
        icon: 'people',
        path: URL.ROLES,
      },
      {
        name: 'Business Unit',
        icon: 'business',
        path: URL.BUSINESS_UNIT,
      },
    ],
  },
  {
    name: 'Master',
    icon: 'account_tree',
    path: SECTIONS.MASTERS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Brand',
        icon: 'branding_watermark',
        path: URL.BRAND,
      },
      {
        name: 'Model',
        icon: 'car_rental',
        path: URL.MODEL,
      },
      {
        name: 'Variant',
        icon: 'car_rental',
        path: URL.VARIANT,
      },
      {
        name: 'Location',
        icon: 'place',
        path: URL.LOCATION,
      },
      {
        name: 'Email Template',
        icon: 'mail',
        path: URL.EMAIL_TEMPLATE,
      },
      {
        name: 'Workflow',
        icon: 'merge_type',
        path: URL.WORKFLOW,
      },
      {
        name: 'Bank Accounts',
        icon: 'account_balance_wallet',
        path: URL.BANK_ACCOUNT,
      },
      {
        name: 'Notifications',
        icon: 'notification_add ',
        path: URL.NOTIFICATION_CHANNEL,
      },
      {
        name: 'Testing Account',
        icon: 'settings',
        path: URL.TESTING_ACCOUNT,
      },
    ],
  },
  {
    name: 'Approval',
    icon: 'approval',
    path: URL.APPROVAL,
    isExpanded: true,
    subFolders: [
      {
        name: 'Pending Approvals',
        icon: 'mark_email_unread',
        path: URL.APPROVAL,
      },
    ],
  },
  {
    name: 'Customer',
    icon: 'person',
    path: SECTIONS.CUSTOMERS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Auto Debit',
        icon: 'local_atm',
        path: URL.AUTO_DEBIT,
      },
      // {
      //   name: 'Payment Schedule',
      //   icon: 'more_time',
      //   path: URL.PAYMENT_SCHEDULE,
      // },
      {
        name: 'Payments',
        icon: 'currency_rupee',
        path: URL.PAYMENTS,
      },
      {
        name: 'Subscriptions',
        icon: 'add',
        path: URL.SUBSCRIPTION,
      },
      {
        name: 'Customers',
        icon: 'person',
        path: URL.CUSTOMERS,
      },
      {
        name: 'Smart warranty',
        icon: 'business_center',
        path: URL.SMART_WARRANTY,
      },
      {
        name: 'Smart Loan',
        icon: 'account_balance',
        path: URL.SMART_LOAN,
      },
    ],
  },
  {
    name: 'Vehicle',
    icon: 'directions_car',
    path: SECTIONS.VEHICLES,
    isExpanded: true,
    subFolders: [
      // {
      //   name: 'Price Calculator',
      //   icon: 'calculate',
      //   path: URL.PRICE_CALCULATOR,
      // },
      {
        name: 'Listed Vehicles',
        icon: 'directions_car',
        path: URL.LISTED_VEHICLE,
      },
      {
        name: 'Inventory Vehicles',
        icon: 'inventory',
        path: URL.INVENTORY_VEHICLE,
      },
      {
        name: 'General Vehicles',
        icon: 'directions_car',
        path: URL.GENERAL_VEHICLE,
      },
      {
        name: 'Insurance',
        icon: 'sticky_note_2',
        path: URL.INSURANCE,
      },
      {
        name: 'PUC',
        icon: 'feed',
        path: URL.PUC,
      },
      {
        name: 'RC',
        icon: 'file_copy',
        path: URL.RC,
      },
      {
        name: 'RTO',
        icon: 'insert_drive_file',
        path: URL.RTO,
      },
      {
        name: 'Car info',
        icon: 'car_crash',
        path: URL.CAR_INFO,
      },
    ],
  },
  {
    name: 'Procurement',
    icon: 'wifi_protected_setup',
    path: SECTIONS.PROCUREMENTS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Procurement Requests',
        icon: 'undo',
        path: URL.PROCUREMENT_REQUEST,
      },
      {
        name: 'Prospect Vehicles',
        icon: 'add_shopping_cart',
        path: URL.PROSPECT_VEHICLE,
      },
      {
        name: 'Inspection Requests',
        icon: 'undo',
        path: URL.INSPECTION_REQUEST,
      },
      {
        name: 'Inspection',
        icon: 'content_paste_search',
        path: URL.INSPECTION,
      },
      {
        name: 'Price Discovery',
        icon: 'price_change',
        path: URL.PRICE_DISCOVERY,
      },
    ],
  },
  {
    name: 'Quotation',
    icon: 'request_quote',
    path: SECTIONS.QUOTATION,
    isExpanded: true,
    subFolders: [
      {
        name: 'Quotations',
        icon: 'list_alt',
        path: URL.QUOTATIONS,
      },
      {
        name: 'On Road Price',
        icon: 'currency_rupee',
        path: URL.ON_ROAD_PRICE,
      },
    ],
  },
  {
    name: 'Finance',
    icon: 'currency_rupee',
    path: SECTIONS.FINANCES,
    isExpanded: true,
    subFolders: [
      {
        name: 'Finance Partner',
        icon: 'handshake',
        path: URL.FINANCE_PARTNER,
      },
      {
        name: 'Car Finance',
        icon: 'savings',
        path: URL.CAR_FINANCE,
      },
      {
        name: 'Finance Partner Admin',
        icon: 'groups',
        path: URL.FINANCE_PARTNER_ADMIN,
      },
    ],
  },
  {
    name: 'Maintenance',
    icon: 'hardware',
    path: SECTIONS.MAINTENANCES,
    isExpanded: true,
    subFolders: [
      {
        name: 'SAM Request',
        icon: 'undo',
        path: URL.SAM_REQUEST,
      },
      {
        name: 'SAM',
        icon: 'local_car_wash',
        path: URL.SERVICE_AND_MAINTENANCE,
      },
      {
        name: 'Pickup and Drop',
        icon: 'undo',
        path: URL.PUD_REQUEST,
      },
    ],
  },
  {
    name: 'Corporate',
    icon: 'badge',
    path: SECTIONS.CORPORATES,
    isExpanded: true,
    subFolders: [
      {
        name: 'Corporate Type',
        icon: 'badge',
        path: URL.CORPORATE_TYPE,
      },
      {
        name: 'Corporate Master',
        icon: 'badge',
        path: URL.CORPORATE_MASTER,
      },
      {
        name: 'MLA',
        icon: 'note_alt',
        path: URL.MASTER_LEASE_AGREEMENT,
      },
      {
        name: 'Corporate Location',
        icon: 'share_location',
        path: URL.CORPORATE_LOCATION,
      },
      {
        name: 'Corporate User',
        icon: 'supervised_user_circle',
        path: URL.CORPORATE_USER,
      },
      {
        name: 'Corporate Subscription',
        icon: 'corporate_fare',
        path: URL.CORPORATE_SUBSCRIPTION,
      },
      {
        name: 'Corporate Admins',
        icon: 'face',
        path: URL.CORPORATE_ADMIN,
      },
    ],
  },
  {
    name: 'Payments',
    icon: 'payments',
    path: SECTIONS.PAYMENTS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Invoice Schedule',
        icon: 'schedule',
        path: URL.INVOICE_SCHEDULE,
      },
    ],
  },
  {
    name: 'E-Rickshaw',
    icon: 'electric_rickshaw',
    path: SECTIONS.E_RICKSHAW,
    isExpanded: true,
    subFolders: [
      {
        name: 'Subscriptions',
        icon: 'electric_rickshaw',
        path: URL.E_RICKSHAW_SUBSCRIPTION,
      },
    ],
  },
  {
    name: 'Dealer',
    icon: 'local_shipping',
    path: SECTIONS.DEALERS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Dealers',
        icon: 'people',
        path: URL.DEALER,
      },
      {
        name: 'Dealer Admins',
        icon: 'face',
        path: URL.DEALER_ADMIN,
      },
      {
        name: 'Workshop',
        icon: 'build',
        path: URL.WORKSHOP,
      },
      {
        name: 'Pickup and drop agency',
        icon: 'business',
        path: URL.PUD_AGENCY,
      },
      {
        name: 'RTO Vendors',
        icon: 'business',
        path: URL.RTO_VENDORS,
      },
    ],
  },
  {
    name: 'Communication',
    icon: 'campaign',
    path: SECTIONS.COMMUNICATIONS,
    isExpanded: true,
    subFolders: [
      {
        name: 'Support',
        icon: 'support_agent',
        path: URL.SUPPORT,
      },
      {
        name: 'Contact Us',
        icon: 'perm_phone_msg',
        path: URL.CONTACT_US,
      },
    ],
  },
];

export const notes: Section[] = [
  {
    name: 'Settings',
    icon: 'settings',
    path: 'settings',
    isExpanded: true,
    subFolders: [],
  },
];
